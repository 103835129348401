label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  display: flex;
  justify-content: center;
  align-items: center;
  .card-wrapper {
    text-align: center;
    // position: absolute;
    // top: 40%;
    // left: 50%;
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    min-width: 500px;

    .ant-card-body {
      padding: 74px;
    }

    @include media('<md') {
      min-width: 320px;
      .ant-card-body {
        padding: 34px;
        .login-logo {
          max-width: 150px !important;
        }
        // .ant-card-meta-title {
        //   font-size: 16px;
        // }
      }
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: center;
  }
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.modal {
  .ant-form-item.upload-wrapper.right {
    .ant-form-item-control {
      justify-content: center;
    }
  }
}

.ant-card {
  border-radius: $table-border-radius;
  box-shadow: $table-box-shadow;

  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.forgotten-password {
  margin-top: 15px;
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #ffffff;
  background: $primary-color;
  text-transform: capitalize;
  border-radius: $table-border-radius-top;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-heading .panel-title span {
  background-color: $primary-color !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
  text-align: left;
  font-weight: 700;
}

input,
select {
  font-size: 100% !important;
}

//ikonice search pen kanta oko

.ant-input-affix-wrapper {
  svg {
    fill: #b3b3b3;
  }
}

.icon-unlock {
  svg {
    fill: #b3b3b3;
  }
}

.ant-avatar.ant-avatar-circle {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 27px;
}

.refundTable .ant-table.ant-table-middle .ant-table-title,
.refundTable .ant-table.ant-table-middle .ant-table-footer,
.refundTable .ant-table.ant-table-middle .ant-table-thead > tr > th,
.refundTable .ant-table.ant-table-middle .ant-table-tbody > tr > td,
.refundTable .ant-table.ant-table-middle tfoot > tr > th,
.refundTable .ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}

.refundTable .ant-table-bordered .ant-table-thead > tr > th {
  padding-top: 35px !important;
}

.ant-table-cell {
  padding-left: 20px;
  // text-align: center !important;
}

.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin-top: 10px;
} //petak

// .panel-primary .panel-body {
//   margin-top: 25px;
// }

.panel-body {
  #dnd-dropzone {
    margin-bottom: 20px !important;
  }
}

.panel-body {
  #featureImage {
    padding-top: 13px;
  }
}

.panel-body {
  #gallery {
    padding-top: 13px;
  }
}

.panel-body {
  #image {
    padding-top: 13px;
  }
}

// .ant-btn:hover, .ant-btn:focus, .ant-btn:active {
//   text-decoration: none;
//   background: violet;
// }

//log edit user + avatar
.ant-layout-sider-children .ant-avatar.ant-avatar-circle {
  display: block;
  margin-left: 85px;
  margin-bottom: 10px;
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}

// Role Popup single instead multiple on the left
// .ant-select-single.ant-select-show-arrow
// .ant-select-selection-item,
// .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
//   text-align: left;
// }

// @-moz-document url-prefix() {
//   html,
//   body {
//     scrollbar-width: none
//   }
// }

* {
  scrollbar-width: none; /* Firefox i Chrome ukidanje svih scrollbar-a*/
}

//dugmic za brisanje OK
.ant-btn.ant-btn-primary.ant-btn-sm {
  padding-left: 60px;
  padding-right: 60px;
  width: auto;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

//body scroll orders
.table-orders-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-orders-okvir .ant-table.ant-table-middle {
  border-radius: 10px;
}

.table-user-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-data-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-tags-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-data-okvir .ant-table-pagination.ant-pagination {
  padding-bottom: 17px !important;
}

.table-attribute-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-declaration-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-newsletter-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-refund-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-invoice-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

.table-post-okvir .ant-table-body {
  max-height: 85vh !important;
  background: white;
}

// paginacija topCenter i razmaci
.ant-table-pagination.ant-pagination {
  margin: 0px 0px 15px 0;
  background: white;
  padding-bottom: 27px;
  margin-top: -57px;
}

//paginacija razmak gore
.usersWrapper .actions-block {
  margin-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
}

// button add user i slicni
.ant-btn,
.ant-input-affix-wrapper-borderless {
  z-index: 5;
}

// search polje users
.ant-input-affix-wrapper.ant-input-affix-wrapper-borderless {
  max-width: 400px;
}

// pozadina active page pagination
.ant-pagination-item-active {
  // background: linear-gradient(180deg, #3372ed 0%, #1138c2 100%);
  background: $primary-color;
  border-radius: 4px;
  min-width: 26px !important;
  height: 26px !important;
}
// pozadina paginacija on hover
.ant-pagination-item-active:hover a,
.ant-pagination-item-active:hover a:visited {
  color: white;
  // background: $secondary-color;
}

// kada nema podataka no-data-box
.no-data-box {
  background: white;
  margin: 2vh;
  border-radius: 8px;
  h2 {
    color: grey;
    font-size: 30px;
    padding-top: 35vh;
    padding-bottom: 35vh;
  }
}

.link-forgot-password {
  color: black !important;
}

.link-forgot-password:hover {
  color: white !important;
  text-decoration: none;
}

.anticon.anticon-export svg {
  margin-right: 10px;
  vertical-align: -0.3em;
}

.ant-drawer-title {
  font-weight: 700;
  font-size: 24px;
}

.email-v {
  .ant-card-meta-title {
    text-align: center !important;
}
}

.user-form {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #555;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #555;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #555;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #555;
}