.ant-btn {
  //text-transform: uppercase;
  height: 40px;
  letter-spacing: 0.05em;
  font-size: 13px;
  border-radius: 2px;
  transition: none;
  // padding: 0 40px; //padding deki

  // .anticon {
  //     vertical-align: text-top;
  //     svg {
  //         color: $wht;
  //     }
  // }

  &:hover,
  &:focus {
    color: #000;
    border-color: #000;
  }
}

.ant-btn-default {
  border-radius: 63px;

  //margin-right: 10px;
  &:hover,
  &:focus {
    color: black;

    border-color: #111;
  }
}


.ant-btn-primary {
  @include btn($black-color, $wht);
  //background-color: $c-primary;
  //border-color: $c-primary;

  //width: 200px;
  border-radius: 63px;
  border: 1px solid $black-color;

  &:hover,
  &:focus {
    //background-color: lighten($c-primary,3);
    //border-color: lighten($c-primary,3);
    color: #111;
    background: $secondary-color;
    cursor: pointer;
    border: 1px solid $black-color;
    transition: 0.1s ease-in-out;

  }
}

.ant-btn-primary {
  .anticon {
    svg {
      color: $wht;

    }
  }
}

.ant-btn-primary:hover .anticon svg,
.ant-btn-primary.hover .anticon svg {
  color: black;

}


.ant-btn-secondary {
  border-radius: 63px;
  font-size: 14px;
  min-width: 200px;

  // font-weight: 600;
  .anticon {
    svg {
      color: $k100;
    }
  }
}



.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  border-color: black;
  color: black;
}

.ant-btn-danger {
  background-color: $danger;
  color: #fff;
  border-color: transparent;

  &:focus {
    color: #fff;
    background-color: darken($danger, 5);
    border-color: transparent;
  }
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
  margin-left: 0px !important;
}

// ikonice search u headeru tabele
.ant-table-filter-trigger {
  svg {
    fill: #b3b3b3;
  }
}