html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
	overflow-x:hidden;
	scroll-behavior: smooth;
	&.ua-ios {
		overflow-y:scroll;
		-webkit-overflow-scrolling:touch;
	}
}

*, *:after, *:before {
	box-sizing:border-box;
}

* {
	margin:0;
	padding:0;
	-webkit-tap-highlight-color: transparent;
	-webkit-tap-highlight-color: rgba(255,255,255,0);
}

a, a:visited {
	outline:none;
	text-decoration:none;
	color:inherit;
	
	&:hover, &:active {
		text-decoration:underline;
		color:inherit;
	}
	
	img {
		border:none;
		outline:none;
	}
}

img {
	max-width:100%;
	height:auto;
}

ul {list-style:none;}

a, button, input, select, textarea, label, summary {
	touch-action: manipulation;
}

audio, canvas, img, svg, video {vertical-align: middle;}
header, footer, nav, article, aside, section, main {display:block;}

.ant-form-item{
  margin-top: 24px;
}

figure {margin: 0;}
.ant-form-item-row{
  display: flex;
  flex-direction: column;
}
.ant-btn-placeholder{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.accepted-status{
  color: #2CC432;
}
.unaccepted-status{
  color: #DE2B2B;
}
.neutral-status{
  color: #CCCCCC;
}
.desna-strana{
  display: flex;
  gap: 10px;
}
.link-view-study{
  color: #3372ED;
  cursor: pointer;
}
.ant-card-body{
  padding: 0;
}