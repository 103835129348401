.home-links {
  padding: 2rem 1rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  a {
    text-decoration: none;
  }
  &__item {
    transform: scale(1);
    cursor: pointer;
    box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 1rem;
    aspect-ratio: 1/1;
    background-color: #fff;
    opacity: 1;

    transition: all ease-in-out 0.2s;

    &:hover {
      transform: scale(1.01);
      opacity: 0.7;
    }

    svg {
      min-width: 4rem;
      min-height: 4rem;
    }
  }
}
