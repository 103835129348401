button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  >* {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right>* {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.log-out-btn {
  @include btn(transparent, lightgray);
  width: 100%;
  color: black;
  font-weight: normal;

}

// .ant-btn {
// 	line-height: normal;
// }

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $primary-color;
}

// .ant-pagination-item:hover a {
//   color: $secondary-color;
// }

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $primary-color;
  // border-color: #1890ff;
}

.ant-input:focus,
.ant-input-focused {
  border-color: $primary-color;
  box-shadow: 0 0 0 2px #333;
}

.ant-input:hover {
  border-color: #555;
}

.ant-pagination-item:hover a {
  color: $primary-color;
}

.btn-drawer {
  width: 45%;
  font-weight: bold;
  color: #fff;
}

.btn-drawer-accept {
  order: 2;
  background: #2CC432;
}

.btn-drawer-decline {
  order: 1;
  background: #DE2B2B;
}

.btn-drawer-decline:hover,
.btn-drawer-decline:focus {
  background-color: #ff4d6d;
  color: white;
  border: none;
  transition: 0.1s ease;
}

.btn-drawer-accept:hover,
.btn-drawer-accept:focus {
  background-color: #70e000;
  color: white;
  border: none;
  transition: 0.1s ease;
}


// .ant-pagination.mini .ant-pagination-item {
//   line-height: 23px;
// }